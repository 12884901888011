import React from "react"
import SEO from "../components/utility/SEO"
import makeStyles from "@material-ui/core/styles/makeStyles";
import {headerHeight} from "../components/Layout";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import GatsbyLink from "../components/utility/GatsbyLink";
import NotFound from "../images/not found.gif"
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: `calc(100vh - ${headerHeight}px)`,
    padding: theme.spacing(2),
    color: theme.palette.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'center'
  },
  image: {
    width: '100%',
    maxHeight: 416,
    objectFit: 'contain',
    marginBottom: theme.spacing(4)
  },
  typography: {
    marginBottom: theme.spacing(4)
  }
}));

const NotFoundPage = () => {
  const classes = useStyles();

  return (
      <>
        <SEO title="404: Not found"/>
        <Box className={classes.root}>
          <img className={classes.image} alt='' src={NotFound}/>
          <Typography className={classes.typography} variant='h3' color='inherit'>
            bond googles is looking around but it seems this page is gone!
          </Typography>
          <GatsbyLink to={'/home'}>
            <Button variant='contained' color='primary'>
              Return to Home
            </Button>
          </GatsbyLink>
        </Box>
      </>
  )
};

export default NotFoundPage
